<template>
  <section>
    <div class="pageBradcrumbs">
      <div class="containerLarge">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb small">
            <li class="breadcrumb-item">
              <b-button @click="toGlobal('/', 'home')">Home</b-button>
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="banner">
      <div
        class="bannerImage"
        :style="{
          'background-image':
            'url(' + require('../assets/img/banner/contact.jpg') + ')',
        }"
      >
        <div class="containerLarge">
          <div class="pageTitle-banner text-center">
            <div class="pageTitle-content">
              <h1 class="mt-4">Contacts</h1>
              <div class="divider"></div>
              <p class="lead">
                Thanks for contact us
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contacts mt-5">
      <div class="containerLarge">
        <div class="row mt-5">
          <div class="col-md-8 offset-md-2 col-lg-6 offset-lg-3 mb-5">
            <div class="border p-3">
              <p class="lead mt-4 text-center">Your message has been forwarded successfully. <br>We will get back to you as soon as possible.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {

  computed: {
    getCurrentPath(){
      return this.$store.getters.getCurrentPath;
    }
  },

  methods: {
    toGlobal(str, current){
      if (str !== this.getCurrentPath){
        this.$store.commit('setCurrentGlobal', current);
        this.$store.commit('setCurrentPath', str);
        this.$router.push(str);
      }      
    },
  }
};
</script>
